// Here you can add other styles

.observations-map {
	height: 800px;
	width: 100%;
}

.grid-container {
	display: inline-grid;
	grid-template-columns: 10px 10px;
	grid-template-rows: 10px 10px;
	vertical-align: top;
}

.panel {
	border: none;
	margin-bottom: 0;
}

.info {
    padding: 6px 8px;
    font: 14px/16px Arial, Helvetica, sans-serif;
    background: white;
    background: rgba(255,255,255,0.8);
    box-shadow: 0 0 15px rgba(0,0,0,0.2);
    border-radius: 5px;
}

textarea.form-control:disabled {
	background-color: white;
	color: #607d8b;
}

button.close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 10px;
  line-height: 14px;
  min-width: auto;
  border: none;
}

.leaflet-pane {
  mix-blend-mode: multiply;
}

.section-observations-map {
  .leaflet-pane {
    mix-blend-mode: normal;
  }
  .leaflet-top {
    margin-top: 55px;
  }
  z-index: 10;
  position: absolute !important;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0px;
  height: calc(100vh - 70px);
  width: 100vw;
  overflow: hidden;
  padding: 0;
}

.section-map {
  position: relative;
  width: 100%;
  height: 100%;
}

.section-observations-table {
  z-index: 100;
  position: absolute;
  margin-top: 35px;
  width: calc(100vw - 70px);
  padding-right: 0;
  height: calc(100vh - 125px);
}

.observations-sidebar {
  margin-top: 35px;
}

.section-observations-filter {
  width: 100vw;
  z-index: 1000;
  position: absolute !important;
  top: 0;
  left: -14px;
  padding-left: 5px;
  padding-top: 5px;
}

.leaflet-layer-grayscale .leaflet-tile {
  filter: grayscale(80%);
}

#overlay {
  position: fixed;
  display: none;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.5);
  z-index: 2000;
}

.ngx-slider-limit {
  visibility: hidden !important;
}

.ngx-slider-model-value {
  visibility: hidden !important;
}

.ngx-slider-ticks {
  visibility: hidden !important;
}

.snowpack-stability-slider span.ngx-slider-ticks span.ngx-slider-tick:nth-of-type(25n+13) {
  visibility: visible !important;
  height: 0;
}

.snowpack-stability-slider span.ngx-slider-ticks span.ngx-slider-tick:nth-of-type(25n+1) {
  visibility: visible !important;
  width: 2px !important;
  height: 15px !important;
  top: -3px !important;
  border-radius: 0 !important;
}

.frequency-slider span.ngx-slider-ticks span.ngx-slider-tick:nth-of-type(25n+13) {
  visibility: visible !important;
  height: 0;
}

.frequency-slider span.ngx-slider-ticks span.ngx-slider-tick:nth-of-type(25n+1) {
  visibility: visible !important;
  width: 2px !important;
  height: 15px !important;
  top: -3px !important;
  border-radius: 0 !important;
}

.avalanche-size-slider span.ngx-slider-ticks span.ngx-slider-tick:nth-of-type(10n+1) {
  visibility: visible !important;
  height: 0;
}

.avalanche-size-slider span.ngx-slider-ticks span.ngx-slider-tick:nth-of-type(20n+1) {
  visibility: visible !important;
  width: 2px !important;
  height: 15px !important;
  top: -3px !important;
  border-radius: 0 !important;
}

.ngx-slider-tick-legend {
  font-size: smaller;
}

.ngx-slider.with-legend {
  margin-bottom: 65px !important;
}

.ngx-slider-pointer {
  top: -10px !important;
  width: 24px !important;
  height: 24px !important;
}

.ngx-slider-pointer:after {
  top: 8px !important;
  left: 8px !important;
  width: 8px !important;
  height: 8px !important;
}

.ngx-slider-pointer.ngx-slider-active:after {
  background-color: $brand-primary !important;
}
